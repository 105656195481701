<template>
  <div>
    <a-form-model
      ref="addPromoForm"
      :model="addPromoForm"
      :rules="addPromoRules"
    >
      <a-row>
        <a-col :span="12">
          <a-form-model-item ref="amount" label="Сумма" prop="amount">
            <a-input v-model="addPromoForm.amount" placeholder="" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="text-right">
          <a-form-model-item ref="currency_id" label="Валюта" prop="currency_id">
            <a-input v-model="addPromoForm.amount" placeholder="" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-row>
      <a-col :span="24" class="text-right">
        <div class="mt-5">
          <a-button type="primary" @click="addPromoSubmit" :loading="addPromoLoading">
            Добавить
          </a-button>
          <a-button style="margin-left: 1rem;" @click="closeModal">
            Отмена
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'addPromocode',
  computed: {
    // ---
  },
  data() {
    return {
      addPromoLoading: false,
      addPromoForm: {
        amount: 0,
        currency_id: undefined,
        login: undefined,
        is_reserved: 0,
      },
      addPromoRules: {
        amount: [{ required: true, message: 'Укажите сумму', trigger: 'change' }],
        currency_id: [{ required: true, message: 'Укажите валюту', trigger: 'change' }],
        login: [{ required: true, message: 'Укажите логин', trigger: 'change' }],
      },
    }
  },
  mounted () {
    //
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    async addPromoSubmit() {
      this.$refs.addPromoForm.validate(valid => {
        if (valid) {
          // ---
          this.addPromoLoading = true
          const url = '/admin/promocodes/olimp/create'
          return apiClient.post(url, this.addPromoForm).then((response) => {
            this.$notification.success({
              message: 'Добавлен новый промокод',
              description: response.data.data.code,
            })
            // this.fetch()
            this.addPromoLoading = false
            this.submitModal(response.data.data)
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Ошибка при добавлении промо-кода(ов)',
              description: error.message,
            })
            this.addPromoLoading = false
          })
        }
      })
    },
    async getCurrencies() {
      //
    },
  },
}
</script>

<style scoped>
</style>
